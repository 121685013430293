.home-text-container {
  background-color: rgba(0, 0, 0, 0.75);
  /* padding: 10px; */
  /* padding-top: 15px; */
  border-radius: 10px;
  color: #fff;
  /* position: absolute; */
}
/*computer*/
@media screen and (min-width: 1000px) {
  .home-text-container {
    /* position: absolute; */
    top: 50%;
    left: 50%;
    transform: translate(-115%, -85%);
    max-width: 30rem;
    position: absolute;
  }
}
/*mobile*/
@media screen and (max-width: 1000px) {
  #home-img {
   width: -webkit-fill-available;
  }
}

#home-container {
  position: relative;
  text-align: center;
  margin: 1rem;
}

.home-text {
  font-size: large;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
}

#home-img {
  color: green;
  border-radius: 50px;
  height: 'auto';
  border-radius: 10px;
  /* width: -webkit-fill-available; */

}

#edit-toggle-button{
  margin-bottom: 1rem;
}