html {
    background-color: #feed9f;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    background-color: #333;
    color: #fff;
}

.left-section {
    display: flex;
    align-items: center;
}

.right-section {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

#logo-link {
    /* width: 15%; */
    width: 15vw;
    height: auto;
    line-height: 0;
}

.nav-links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-family: "Roboto Condensed", Sans-serif;
    font-size: 17px;
    font-weight: 800;
    text-transform: uppercase;
}

.nav-links li {
    display: inline;
    margin-right: 10px;
    /* Adjust as needed */
}

.nav-links li:last-child {
    margin-right: 0;
}

.nav-links li a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
}

.nav-links li a:hover {
    color: #feed9f;
}