#customers tr:nth-child(even) {
    background-color: #f2f2f2;
}

/* #customers tr:hover {
    background-color: #ddd;
} */

#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #333;
    color: white;
}

#customers td {
  border: 1px solid #ddd;
  padding: 8px;
  max-width: 30vw;
  overflow: hidden;
}

#customers {
    margin: auto;
    background-color: white;
    border-spacing: 0px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 10px;
    overflow: hidden;
} 

#delete-button {
    font-size: x-large;
}

a { 
    color: blue;
}

#sign-out-button{
    background-color: white;
    margin-bottom: 1rem;
}
