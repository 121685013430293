.simple-form-container {
  max-width: 800px;
  margin: 0 auto;
  background: #333;
  border-radius: 15px;
}

.simple-form {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.simple-form input,
.simple-form textarea {
  margin: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.simple-form button {
  padding: 10px;
  margin: 10px 100px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.simple-form button:hover {
  background-color: #0056b3;
}

#input-image {
  align-self: baseline;
  color: white;
  border: none;
}

#view {
  margin: 1rem;
}