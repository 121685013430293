.contact-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    max-width: 800px;
}

.contact-info,
.map {
    flex: 1;
    text-wrap: nowrap;
}

.contact-info {
    line-height: 10px;
}

.contact-title {
    line-height: 20px;
}

/* Mobile Width */
@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
    }

    #google-maps {
        height: 300px;
        width: 300px;
    }
}

/* Desktop Width */
@media (min-width: 768px) {
    #google-maps {
        height: 325px;
        width: 500px;
    }
}

#google-maps {
    border: none;
}

#contact-parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.instagram-container svg {
    vertical-align: middle;
}