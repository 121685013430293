.request-form-container {
    max-width: 800px;
    margin: 0 auto;
    background: #333;
    border-radius: 15px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}


.request-form {
  display: flex;
  flex-direction: column;
  padding: 15px;
}


.request-form input,
.request-form textarea {
  margin: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.request-form-label{
  align-self: self-start;
  padding-left: 1rem;
  color: white;
}

#state {
  margin: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px; 
  width: calc(100% - 20px); 
  box-sizing: border-box; 
}

#request-form-img {
  max-width: 100%;
  height: auto; 
  margin-top: 10px;
}

