.grid-container {
  column-count: 2;
  column-gap: 1rem;
  padding: 2rem;
  max-width: 1200px;
  margin: auto;
}

.grid-item {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  break-inside: avoid;
}

.grid-item img {
    max-height: 900px;
    width: 90%;
    padding-top: 1.5rem;
}


.grid-item p {
  padding: 10px;
  margin: 0;
  text-align: center;
  color: #333;
}

#card-lower {
  display: flex;
  justify-content: center;
}
